document.querySelectorAll(".dontspam").forEach(dontSpam => {
  const user = dontSpam.querySelector(".dontspam-u").innerText;
  const domain = dontSpam.querySelector(".dontspam-d").innerText;
  const tld = dontSpam.querySelector(".dontspam-tld").innerText;
  const email = user + "@" + domain + "." + tld;

  const mailtoLink = document.createElement("a");
  mailtoLink.href = "mailto:" + email;
  mailtoLink.innerText = email;
  dontSpam.parentNode.replaceChild(mailtoLink, dontSpam);
});
